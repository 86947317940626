<template>
    <div class="sign forgot">
        <div class="left_area">
            <div class="logo">
                <img :src="env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo2.svg') : require('@/assets/images/logo2.png')">
            </div>
            <h2>Forgot Your Password</h2>
            <p>Type your email address to send instructions to reset your password to your inbox.</p>
            <div class="alert alert-success" v-if="forgotPasswordMessage" v-text="forgotPasswordMessage"></div>
            <Form @submit="handleForgot" :validation-schema="schema" v-slot="{ errors }">
                <div class="group_item">
                    <label class="input_label">E-mail</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.email }">
                        <Field autocomplete="off" name="email" type="email" placeholder="example@onboardme.io" />
                    </div>
                    <ErrorMessage name="email" class="text-danger" />
                </div>
                <button class="primary_btn" :disabled="formLoader">
                    <span v-if="formLoader"><i class="fa fa-spin fa-spinner"></i> Sending Link</span>
                    <span v-else>Reset Password</span>
                </button>
            </Form>
            <div class="forgot">
                <router-link to="/login">Login</router-link>
            </div>
        </div>
        <div class="right_area">
            <img :src="require('@/assets/images/help-center.svg')">
            <h5>Hey, Coach</h5>
            <p>Please check your email for a link to reset your password. Sometimes these message end up in your spam folder. If you need help contact support.</p>
            <a :href="env.VUE_APP_DOMAIN_URL ? env.VUE_APP_DOMAIN_URL : 'https://onboardme.io'">{{ env.VUE_APP_DOMAIN_URL ? getDomain(env.VUE_APP_DOMAIN_URL) : 'onboardme.io' }}</a>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import * as yup from 'yup'

    export default {
        name: "Forgot",

        data () {
            const schema = yup.object({
                email: yup.string().required().email().label('The email'),
            });

            return {
                schema,
                env: {},
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            forgotPasswordMessage: state => state.authModule.forgotPasswordMessage,
            formLoader: state => state.authModule.formLoader,
        }),

        mounted () {
            const vm = this;

            vm.env = process.env;
        },

        methods: {
            ...mapActions({
                forgot: 'authModule/forgot',
            }),

            handleForgot (params, { setFieldError, resetForm }) {
                const vm = this;
                params.setFieldError = setFieldError;
                params.resetForm = resetForm;

                vm.forgot(params);
            },

            getDomain (url) {
                const link = new URL(url);

                return link.hostname;
            },
        },
    }
</script>
